import React from "react";
import { useNavigate } from "react-router-dom";

const Planreset = () => {

  const navigate = useNavigate();
  
  const resetPlan = () => {
    navigate("/payment")
  }
  return (
    <section>
      <div className="flex items-center justify-center px-4 py-10 sm:px-6 sm:py-16 lg:px-8 lg:py-24">
        <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md inner_bg">
          <h1 className="text-center text-xl font-bold leading-tight main_heading">
            You do not currently have an active subscription plan
          </h1>

          <div className="flex justify-center mt-4">
            
              <button
              onClick={()=>resetPlan()}
                type="submit"
                className="inline-flex w-40 m-auto items-center justify-center rounded-md bg-black px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-black/80 get_started"
              >
                Reset
              </button>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default Planreset;
